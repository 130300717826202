import React from "react"
import PageRoot from "../components/PageRoot"
import ContainerWithPadding from "../components/ContainerWithPadding"
import { useTranslation } from "react-i18next"

const AboutPage = () => {
  const { t } = useTranslation()

  return (
    <PageRoot title={t("label:aboutUs")} showTitle>
      <ContainerWithPadding size={"small"}>
        <div className="int-page">
          <div className="int-wrapper">
            <p>
              {t("message:aboutUsMessage1")}
              <br />
              <br />
              {t("message:aboutUsMessage2")}
              <br />
              <br />
              {t("message:aboutUsMessage3")}
              <br />
              <br />
            </p>
          </div>
        </div>
      </ContainerWithPadding>
    </PageRoot>
  )
}

export default AboutPage
